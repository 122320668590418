
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import { ExperienceClient } from '@/services/Services';
import ExperienceResume from '../../components/experience/experienceResume.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ExperienceResume
    }
})
export default class ExperienceDetailModal extends Vue {

    @Prop() experienceIdentifier : string;

    model: OM.ExperienceDetailVM = new OM.ExperienceDetailVM();

    created() {
        ExperienceClient.getById(this.experienceIdentifier)
        .then(x => {
            this.model = x;
        })
    }

}
