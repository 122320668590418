
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ReportQuestion from '@/components/reportQuestion.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import InsertTextModal from '@/components/modals/insertTextModal.vue';
import * as OM from '@/Model';
import { BlockedUserClient } from '@/services/Services';

@Options({
    components: {
        ReportQuestion
    }
})
export default class ChatSettingsModal extends Vue {

    @Prop() personName: string;
    @Prop() appUserIdentifier: string;
    @Prop() chatRoomIdentifier: string;

    @Prop() affinityCallback: any;

    created() {
    }

    takeOffAffinity() {
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_EliminataAffinitaNonPuoEssereRecuperataVuoiProcedere?", "Una volta eliminata l’affinità non potrà più essere recuperatata. <br />Vuoi procedere?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_No", "No"),
            confirmCb: () => {
                this.affinityCallback();
            },
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    blockUser(){
        this.$opModal.show(InsertTextModal, {
            titleKey: "app_PerchéVuoiSegnalareQuestaPersona?",
            titleValue: "Perché vuoi segnalare questa persona?",
            inputPlaceholderKey: "app_blockReason",
			inputPlaceholderValue: "Block reason...",
			buttonKey: "app_block",
			buttonText: "Block",
			value: "",
			callback: (note: string) => {
                var report = new OM.BlockUserVM();
                report.blockedUserIdentifier = this.appUserIdentifier;
                report.note = note;

                this.$opModal.show(InfoModal, {
                    text: this.$localizationService.getLocalizedValue("app_SeiSicuroDiVolerBloccareLUtente?", "Sei sicuro di voler bloccare l'utente?"),
                    confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
                    deny: this.$localizationService.getLocalizedValue("app_No", "No"),
                    confirmCb: () => {
                        BlockedUserClient.block(report)
                        .then(x => {
                            this.$opModal.show(InfoModal, {
                                img: "feven_squared_violet.svg",
                                text: this.$localizationService.getLocalizedValue("app_utenteBloccatoConSuccesso!", "Utente bloccato con successo!"),
                                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                                confirmCb: () => {
                                    this.$opModal.closeAll();
                                    this.$router.replace("/affinity");
                                }
                            })
                        })
                        .catch(err => {
                            this.$opModal.show(InfoModal, {
                                img: "face_error.svg",
                                text: err.Message,
                                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                                confirmCb: () => {
                                    this.$opModal.closeLast();
                                }
                            })
                        })
                    },
                    denyCb: () => {
                        this.$opModal.closeLast();
                    }
                })
			}
        })
    }

}
