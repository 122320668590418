
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { WSSServices } from '@/services/WSSServices';
import { ChatClient, MatchClient, NotificationClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import { getUniversalISOStringDate } from '@/utils';
import InfoModal from '@/components/modals/infoModal.vue';
import ChatSettingsModal from '../modals/chatSettingsModal.vue';
import ChatProfileDetail from '../modals/chatProfileDetail.vue';
import ChatMatchModal from '../modals/chatMatchModal.vue';
import ProfileImagesAndDescriptionModal from '../modals/profileImagesAndDescriptionModal.vue';
import store from '@/store';
import router from '@/router';
import * as OM from '@/Model';
import ThirdSlide from '@/views/onboarding/components/appdescription/thirdslide.vue';
import ExperienceDetailModal from '../modals/experience/experienceDetailModal.vue';
import { CommonServices } from '@/services/CommonServices';

@Options({
    components: {
    }
})
export default class ChatDetail extends Vue {

    chatRoom: OM.ChatRoomVM = new OM.ChatRoomVM();
    chatMessages: OM.ChatMessageVM[] = [];
    chatRoomIdentifier: string = "";

    file: File = null;
    messageText: string = "";
    messageInputKey: number = 0;
    chatWriter: HTMLElement;
    questionAttached: OM.ProfileQuestionAttached = null;
    textAttached: OM.TextMessageAttached = null;

    loggedUserIdentifier: string = "";
    style: string = "";
    fixedBottomStyle: string = "";

    interactionCount: number = 0;

    hasDown = false; //il click o il touch è iniziato sulla notifica
    isMoving = false; //c'è stato un movimento dall'inizio del click, se non c'è stato chiamerà "onclick" al rilascio
    notificationRef = null; //html element preso tramite getbyid 

    maxX = 0; //il massimo di translateY applicabile (viene settato come getBoundingClientRect().bottom di notificationRef)
    startX = 0; //Y dell'inizio del click o del touch
    moveX = 0; //Y del mousemove o touchmove

    diff = 0; //viene inizializzato come il minTranslateX poi viene usato in style nella notification come translateY
    minTranslateXForClose = 50; //il minimo di variazione per pinnare il messaggio
    selectedMessageId: string = "";
    
    created(){
        document.addEventListener("resume", this.resumeCallback, false);
        document.addEventListener("pause", this.pauseCallback, false);
        
        this.init();
    }

    resumeCallback(){
        this.init();
    }

    pauseCallback(){
        WSSServices.disconnectChat(this.chatRoomIdentifier, this.onMessageReceive, this.onTakeOffAffinity);
    }

    init() {
        var loggedUser = StorageServices.getLoggedUser();
        this.loggedUserIdentifier = loggedUser.identifier;
        this.chatRoomIdentifier = <string>this.$route.params.identifier;

        WSSServices.connectChat(this.chatRoomIdentifier, this.onMessageReceive, this.onTakeOffAffinity);
        
        this.fixedBottomStyle = "position: relative; top: unset; left: unset;";

        Promise.all([
            ChatClient.getChatRoom(this.chatRoomIdentifier),
            ChatClient.getLast50Messages(this.chatRoomIdentifier, 0),
            NotificationClient.readAllAffinityNotification(this.chatRoomIdentifier),
            NotificationClient.getAffinityNotification(),
        ])
        .then(xs => {
            this.chatRoom = xs[0];
            this.chatMessages = xs[1];
            
            var notifications = xs[3];
            var keys = Object.keys(notifications);
            var newMessagesAmount = 0;

            if(keys.indexOf(this.chatRoom.chatRoomIdentifier) == -1)
                newMessagesAmount = 0;
            else
                newMessagesAmount = notifications[this.chatRoom.chatRoomIdentifier];

            if(store.state.affinityNotificationNumber > 0)
                store.state.affinityNotificationNumber -= newMessagesAmount;
        })
        .finally( () => {
            this.$nextTick( () => {
                this.scrollToBottom(false);
            })
        })
    }

    mounted() {
        this.manageWriterContainerPosition(false);

        window.addEventListener('resize', this.resizeCallback);
        window.addEventListener('keyboardWillShow', this.keyboardWillShowCallback);
        window.addEventListener('keyboardWillHide', this.keyboardWillHideCallback);

        if(window.cordova){
            document.addEventListener("touchmove", this.onTouchMove);
            document.addEventListener("touchend", this.onUp);
        } else {
            document.addEventListener("mousemove", this.onMouseMove);
            document.addEventListener("mouseup", this.onUp);
        }
    }

    resizeCallback(){
        this.scrollToBottom(false);
    }

    keyboardWillShowCallback(event){
        this.manageWriterContainerPosition(true);
    }

    keyboardWillHideCallback(event){
        this.manageWriterContainerPosition(false);
    }

    //mouse
    onMouseDown(messageId: string, ev: MouseEvent) {
        this.hasDown = true;
        this.startX = ev.clientX;
        this.selectedMessageId = messageId;
    }
    onMouseMove(ev: MouseEvent) {
        if(!this.hasDown)
            return;

        this.isMoving = true;
        this.moveX = ev.clientX;
       
        this.checkMove();
    }

    //touch
    onTouchStart(messageId: string, ev: TouchEvent) {
        this.hasDown = true;
        this.startX = ev.changedTouches[0].clientX;
        this.selectedMessageId = messageId;
    }
    onTouchMove(ev: TouchEvent) {
        if(!this.hasDown)
            return;

        this.isMoving = true;
        this.moveX = ev.changedTouches[0].clientX;

        this.checkMove();
    }

    checkMove() {
        var tempDiff = this.moveX - this.startX;
            
        if(tempDiff >= this.minTranslateXForClose){
            this.diff = tempDiff;
            this.hasDown = false;

            var messageEl = document.getElementById(this.selectedMessageId);
            messageEl.style.transform = "translateX(" + this.diff + "px)";
            messageEl.classList.add("showingReplyImage");

            setTimeout(() => {
                messageEl.style.transform = "translateX(0px)";
                messageEl.classList.remove("showingReplyImage");

                var chatMessageAttached = this.chatMessages.filter(x => x.identifier == this.selectedMessageId)[0];
                this.questionAttached = null;
                this.textAttached = new OM.TextMessageAttached();
                this.textAttached.chatMessageIdentifier = chatMessageAttached.identifier;
                this.textAttached.senderInfo.identifier = chatMessageAttached.senderIdentifier;
                this.textAttached.senderInfo.text = this.senderIsMe(chatMessageAttached.senderIdentifier) ? '' : this.chatRoom.sender.profileName;
                this.textAttached.text = chatMessageAttached.text;
            }, 200);

            return;
        }
    }

    onUp(){
        if(!this.hasDown)
            return;

        if(!this.isMoving){
            this.hasDown = false;
            return;
        }

        setTimeout(() => {
            this.diff = 0;
            this.hasDown = false;
            this.isMoving = false;
        }, 100);
    }

    onTakeOffAffinity(){
        router.replace("/affinity");
    }

    textAreaInput(){
        var el = this.$refs.chatInput as any;

        el.style.height = "42px";
        var heightInt = (el.scrollHeight < 85) ? (el.scrollHeight) : 85;

        var height = heightInt.toString() + "px";

        if(heightInt < 42)
            height = "42px";
        
        el.style.height = height;

        this.scrollToBottom(false);
    }

    scrollToBottom(afterMessageSended: boolean) {     
        this.manageWriterContainerPosition(this.interactionCount > 0 && !afterMessageSended);

        this.$nextTick( () => {
            var pagePadding = document.getElementsByClassName("page_padding")[0];
            var chatMessages = document.getElementsByClassName("chatMessages")[0];
            pagePadding.scrollTo(0, chatMessages.clientHeight);

            this.interactionCount += 1;
        })
    }

    manageWriterContainerPosition(addSpace: boolean) {
        var style = "margin-top: var(--notch-inset-top); height: calc(100vh - env(safe-area-inset-bottom) - 100px - ";
        var writerContainer = document.getElementsByClassName("writerContainer")[0];
        var bottomEffettivo = writerContainer.clientHeight;

        style += bottomEffettivo + "px" + ")";
        this.style = style;
    }

    onMessageReceive(val: string) {
        var chatMex = JSON.parse(val) as OM.ChatMessageVM;

        if(chatMex.senderIdentifier == this.loggedUserIdentifier)
            return;

        this.setNewMessage(chatMex.identifier, chatMex.text, chatMex.senderIdentifier, (new Date()).toISOString(), chatMex.profileQuestion, chatMex.textMessageAttached);
        this.scrollToBottom(false);
    }

    setNewMessage(mexId: string, text: string, senderId: string, createdOn: string, profileQuestion: OM.ProfileQuestionAttached, textMessageAttached: OM.TextMessageAttached) {
        var newMessageReceived = new OM.ChatMessageVM();
        newMessageReceived.chatRoomIdentifier = this.chatRoom.chatRoomIdentifier;
        newMessageReceived.identifier = mexId;
        newMessageReceived.text = text;
        newMessageReceived.senderIdentifier = senderId;
        newMessageReceived.createdOn = createdOn;
        newMessageReceived.profileQuestion = profileQuestion;
        newMessageReceived.textMessageAttached = textMessageAttached;
        this.chatMessages.push(newMessageReceived);
    }

    get isDisabled(){
        return !this.messageText.trim();
    }

    senderIsMe(senderId: string) {
        if(senderId == this.loggedUserIdentifier)
            return true;
        else
            return false;
    }

    openSettingsModal() {
        this.$opModal.show(ChatSettingsModal, {
            personName: this.chatRoom.sender.profileName,
            appUserIdentifier: this.chatRoom.sender.appUserIdentifier,
            chatRoomIdentifier: this.chatRoom.chatRoomIdentifier,
            affinityCallback: () => {
                CommonServices.showSpinner();
                WSSServices.takeOffAffinity(this.chatRoom.chatRoomIdentifier)
                .then( () => {
                    this.$opModal.closeAll();
                    this.$opModal.show(InfoModal, {
                        img: "feven_squared_violet.svg",
                        text: this.$localizationService.getLocalizedValue("app_takeOffAffinitySuccess", "Affinity removed with success"),
                        confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                        confirmCb: () => {
                            this.$opModal.closeAll();
                            this.$router.replace("/affinity");
                        }
                    })
                }).catch( err => {
                    this.$opModal.show(InfoModal, {
                        img: "face_error.svg",
                        text: err.Message,
                        confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                        confirmCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                }).finally( () => {
                    CommonServices.hideSpinner();
                })
            }
        })
    }

    checkMessage(){
        (<any>this.$refs.chatInput).click();
        (<any>this.$refs.chatInput).focus();
        
        var tempMessage = this.messageText.trim();

        while (tempMessage.length >= 4 && tempMessage.substring(tempMessage.length - 4) == "<br>")
            tempMessage = tempMessage.substring(0, tempMessage.length - 4).trim();

        while (tempMessage.length >= 4 && tempMessage.substring(0, 4) == "<br>")
            tempMessage = tempMessage.substring(4, tempMessage.length).trim();
        
        while (tempMessage.length >= 2 && tempMessage.substring(tempMessage.length - 2) == "\n")
            tempMessage = tempMessage.substring(0, tempMessage.length - 2).trim();

        while (tempMessage.length >= 2 && tempMessage.substring(0, 2) == "\n")
            tempMessage = tempMessage.substring(2, tempMessage.length).trim();

        if(!tempMessage)
            return;

        this.messageText = "";
        this.$nextTick( () => {
            this.textAreaInput();
        })
        // this.messageInputKey = Math.random();

        this.manageWriterContainerPosition(true);

        this.sendMessage(tempMessage);
    }

    async sendMessage(message: string) {
        var now = (new Date()).toISOString();
        var chatMessageVM = new OM.ChatMessageVM();
        chatMessageVM.chatRoomIdentifier = this.chatRoom.chatRoomIdentifier;
        chatMessageVM.text = message;
        chatMessageVM.senderIdentifier = this.loggedUserIdentifier;
        chatMessageVM.createdOn = now;
        chatMessageVM.utcDatetime = now;
        chatMessageVM.profileQuestion = this.questionAttached;
        chatMessageVM.textMessageAttached = this.textAttached;

        WSSServices.sendMessage(this.chatRoom.chatRoomIdentifier, chatMessageVM)
        .then( x => {
            chatMessageVM.identifier = x; 
            this.setNewMessage(chatMessageVM.identifier, chatMessageVM.text, this.loggedUserIdentifier, chatMessageVM.createdOn, chatMessageVM.profileQuestion, chatMessageVM.textMessageAttached);
            this.questionAttached = null;
            this.textAttached = null;
            setTimeout(() => {
                this.scrollToBottom(true);
            }, 100);
        })
    }

    updateMessages() {
        ChatClient.getLast50Messages(this.chatRoom.chatRoomIdentifier, 0)
        .then(x => {
            this.chatMessages = x;
        })
    }

    beforeUnmount(){
        this.pauseCallback();
        document.removeEventListener("resume", this.resumeCallback, false);
        document.removeEventListener("pause", this.pauseCallback, false);

        window.removeEventListener('resize', this.resizeCallback);
        window.removeEventListener('keyboardWillShow', this.keyboardWillShowCallback);
        window.removeEventListener('keyboardWillHide', this.keyboardWillHideCallback);

        if(window.cordova){
            document.removeEventListener("touchmove", this.onTouchMove);
            document.removeEventListener("touchend", this.onUp);
        } else {
            document.removeEventListener("mousemove", this.onMouseMove);
            document.removeEventListener("mouseup", this.onUp);
        }
    }

    openProfileDetail(){
        this.$opModal.show(ChatProfileDetail, {
            myMatch: this.chatRoom.myMatch,
            otherMatch: this.chatRoom.otherMatch,
            senderProfileName: this.chatRoom.sender.profileName,
            experienceIdentifier: this.chatRoom.experienceIdentifier,
            showExperienceDetail: () => {
                this.$opModal.closeLast();
                this.$opModal.show(ExperienceDetailModal, {
                    experienceIdentifier: this.chatRoom.experienceIdentifier
                })
            },
            profileCb: () => {
                this.$opModal.closeLast();
                var otherUserId = this.chatRoom.sender.appUserIdentifier;
                // this.$router.push('/profile/' + otherUserId)
                this.$opModal.show(ProfileImagesAndDescriptionModal, {
                    userIdentifier: otherUserId
                })
            },
            questionCb: (identifier: string) => {
                this.$opModal.closeLast();
                // this.$router.push('/match/' + identifier)
                this.showChatMatch(identifier, 0);
            },
            answerToOther: () => {
                this.$opModal.closeLast();
                this.$router.push('/new-match/' + this.chatRoom.affinityIdentifier + '/' + this.chatRoomIdentifier);
                //this.createReverseMatch();
            }
        })
    }

    createReverseMatch() {
        this.$router.push('/new-match/' + this.chatRoom.myMatch.matchIdentifier + '/' + this.chatRoomIdentifier);
    }

    showChatMatch(replierIdentifier: string, questionIndex: number) {
        this.$opModal.show(ChatMatchModal, {
            affinityIdentifier: this.chatRoom.affinityIdentifier,
            replierIdentifier: replierIdentifier,
            slideIndex: questionIndex,
            callback: (questionAttached: OM.ProfileQuestionAttached) => {
                this.textAttached = null;
                this.questionAttached = questionAttached;
                this.$opModal.closeLast();
            }
        })
    }

    get getQuestionAttachedTitle() {
        if(!this.questionAttached)
            return;

        var ris = this.questionAttached.name.trim();
        if(this.questionAttached.birthDate)
            ris += ", " + this.$filters.age(this.questionAttached.birthDate);

        return ris;
    }

    get styleHeightAndPadding() {
        var ris = this.style;
        
        if(this.questionAttached) {
            ris = ris.substring(0, ris.length - 1);
            ris = ris + " - 95px);";
        }

        if(this.textAttached) {
            ris = ris.substring(0, ris.length - 1);
            ris = ris + " - 60px);";
        }
        
        return ris;
    }

    scrollTo(messageId: string) {
        var scrollElement = document.getElementById("scrollSection");
        var messageElement = document.getElementById(messageId);
        messageElement.classList.add("pinned");

        scrollElement.scrollTo({
            top: messageElement.offsetTop - messageElement.scrollHeight - 96,
            left: 0,
            behavior: "smooth",
        });

        setTimeout(() => {
            messageElement.classList.remove("pinned");
        }, 1500);
    }
}
