
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import myQuestionNotAvailableModal from './myQuestionNotAvailableModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class ChatProfileDetail extends Vue {

    @Prop() myMatch: OM.ChatRoomMatchSummaryVM;
    @Prop() otherMatch: OM.ChatRoomMatchSummaryVM;
    @Prop() senderProfileName: string;
    @Prop() experienceIdentifier: string;

    @Prop() questionCb: (identifier: string) => void;
    @Prop() profileCb: () => void;
    @Prop() answerToOther: () => void;
    @Prop() showExperienceDetail: () => void;

    created() {
    }

    myQuestionNotAvailable(){
        this.$opModal.show(myQuestionNotAvailableModal, {
            userName: this.senderProfileName
        })
    }

}
