
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppUserClient, MatchClient } from '@/services/Services';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import viewDescriptionModal from './viewDescriptionModal.vue';
import * as OM from "@/Model";

@Options({
    components: {
        LoveFaceShadowBar,
    }
})
export default class ProfileImagesAndDescriptionModal extends Vue {
    
    @Prop() userIdentifier: string;

    profile: OM.ProfileSummaryVM = new OM.ProfileSummaryVM();
    localIndex: number = 0;
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        AppUserClient.getOtherProfile(this.userIdentifier)
        .then( x => {
            this.loaded = true;
            this.profile = x;
        })
        .catch( err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Riprova", "Riprova"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                    this.init();
                },
                deny: this.$localizationService.getLocalizedValue("app_Indietro", "Indietro"),
                denyCb: () => {
                    this.$opModal.closeAll();
                },
            })
        })
    }

    openDescription(){
        if(!this.profile.biography)
            return;

        this.$opModal.show(viewDescriptionModal, {
            ownDescription: false,
            completeName: this.profile.name,
            birthData: this.profile.birthDate,
            meters: 0,
            sexualOrientation: this.profile.sexualOrientation,
            description: this.profile.biography
        })
    }

}
