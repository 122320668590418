
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { MatchClient } from '@/services/Services';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import * as OM from "@/Model";

@Options({
    components: {
        LoveFaceShadowBar,
    }
})
export default class ChatMatchModal extends Vue {

    @Prop({
        default: 0
    }) slideIndex: number;
    
    @Prop() affinityIdentifier: string;
    @Prop() replierIdentifier: string;
    @Prop() callback: any;

    match: OM.MatchDetailVm = new OM.MatchDetailVm();

    created(){
        MatchClient.getMatchDetail(this.affinityIdentifier, this.replierIdentifier)
        .then(x => {
            this.match = x;
        })
    }

    answerClicked(questionAttached: OM.ProfileQuestionAttached) {
        questionAttached.replierIdentifier = this.replierIdentifier;
        questionAttached.name = this.match.name;
        questionAttached.birthDate = this.match.birthDate;
        this.callback(questionAttached);
    }

}
